import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1b14df99"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "vue3-autocomplete-container" };
const _hoisted_2 = ["placeholder"];
const _hoisted_3 = ["onClick", "innerHTML"];
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", _mergeProps({
            ref: "autocompleteRef",
            type: "text",
            onInput: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => ($setup.handleInput && $setup.handleInput(...args)))
        }, _ctx.$attrs, {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ($setup.searchText = $event)),
            placeholder: $props.placeholder,
            class: $setup.getInputClass,
            onFocus: _cache[2] || (_cache[2] =
                //@ts-ignore
                (...args) => ($setup.displayResults && $setup.displayResults(...args))),
            onBlur: _cache[3] || (_cache[3] =
                //@ts-ignore
                (...args) => ($setup.hideResults && $setup.hideResults(...args)))
        }), null, 16, _hoisted_2), [
            [_vModelText, $setup.searchText]
        ]),
        ($setup.shouldShowResults)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle({ width: $setup.inputWidth + 'px' }),
                class: _normalizeClass($setup.getResultsContainerClass)
            }, [
                ($props.useHtmlForResults)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.filteredResults, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: item,
                            class: _normalizeClass($setup.getResultsItemClass),
                            onClick: ($event) => ($setup.clickItem(item)),
                            onMousedown: _cache[4] || (_cache[4] = _withModifiers(() => { }, ["prevent"])),
                            innerHTML: $props.displayItem(item)
                        }, null, 42, _hoisted_3));
                    }), 128))
                    : _createCommentVNode("", true),
                (!$props.useHtmlForResults)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.filteredResults, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: item,
                            class: _normalizeClass($setup.getResultsItemClass),
                            onClick: ($event) => ($setup.clickItem(item)),
                            onMousedown: _cache[5] || (_cache[5] = _withModifiers(() => { }, ["prevent"]))
                        }, _toDisplayString($props.displayItem(item)), 43, _hoisted_4));
                    }), 128))
                    : _createCommentVNode("", true)
            ], 6))
            : _createCommentVNode("", true)
    ]));
}
