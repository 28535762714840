import { ref, computed, onMounted } from 'vue';
export default {
    name: 'Autocomplete',
    inheritAttrs: false,
    props: {
        debounce: {
            type: Number,
            default: 0
        },
        inputClass: {
            type: Array,
            default: []
        },
        useHtmlForResults: {
            type: Boolean,
            default: false
        },
        max: {
            type: Number,
            default: 10
        },
        placeholder: {
            type: String,
            default: ''
        },
        results: {
            type: Array,
            default: []
        },
        resultsContainerClass: {
            type: Array,
            default: []
        },
        resultsItemClass: {
            type: Array,
            default: []
        },
        displayItem: {
            type: Function,
            default: (item) => {
                // @ts-ignore
                return typeof item === 'string' ? item : item.name;
            }
        }
    },
    emits: [
        'input',
        'onSelect'
    ],
    setup(props, context) {
        const autocompleteRef = ref();
        let inputWidth = ref(0), searchText = ref(''), timeout, showResults = ref(true);
        /**
         * Same as Vue2 'mounted' function, used to get refs correctly
         */
        onMounted(() => {
            inputWidth.value = autocompleteRef.value.offsetWidth - 2;
        });
        /**
         * Triggered on input changes with a dynamic debounce
         * @param { InputEvent } e
         */
        function handleInput(e) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                context.emit('input', e.target.value);
            }, props.debounce);
        }
        /**
         * Triggered on click on a result item
         */
        function clickItem(data) {
            context.emit('onSelect', data);
            showResults.value = false;
        }
        /**
         * Called on focus
         */
        function displayResults() {
            showResults.value = true;
        }
        /**
         * Called on blur
         */
        function hideResults() {
            showResults.value = false;
        }
        /**
         * Manually update the displayed text in the input
         * @param value
         */
        function setText(value) {
            searchText.value = value;
        }
        /**
         * Return class(es) for input element
         */
        const getInputClass = computed(() => {
            return props.inputClass.length > 0 ? props.inputClass : ['vue3-input'];
        });
        /**
         * Return class(es) for results container element
         */
        const getResultsContainerClass = computed(() => {
            return props.resultsContainerClass.length > 0 ?
                props.resultsContainerClass :
                ['vue3-results-container'];
        });
        /**
         * Return class(es) for results item elements
         */
        const getResultsItemClass = computed(() => {
            return props.resultsItemClass.length > 0 ?
                props.resultsItemClass :
                ['vue3-results-item'];
        });
        /**
         * Show results depending on results length and showResults boolean
         */
        const shouldShowResults = computed(() => {
            return showResults.value && (props.results.length > 0);
        });
        /**
         * Return results filtered with the 'max' props
         */
        const filteredResults = computed(() => {
            return props.results.slice(0, props.max);
        });
        /**
         * Return data, making them reactive
         */
        return {
            searchText,
            showResults,
            autocompleteRef,
            inputWidth,
            displayResults,
            hideResults,
            handleInput,
            clickItem,
            setText,
            filteredResults,
            getInputClass,
            getResultsContainerClass,
            getResultsItemClass,
            shouldShowResults
        };
    }
};
